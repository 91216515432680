<template>
	<div class="login" v-if="$route.path !== '/login'">
		<template v-if="!forgotPassword">
			<form class="login-form">
				<label for="j_username">Email Address</label>
				<input
					type="text"
					id="j_username"
					name="j_username"
					class="text"
					style="cursor: auto; font-size: 16px"
					v-model="loginDetails.username"
					@keyup.enter="login()"
				/>

				<label for="j_password">Password</label>
				<input
					type="password"
					id="j_password"
					name="j_password"
					class="text"
					style="cursor: auto; font-size: 16px"
					v-model="loginDetails.password"
					@keyup.enter="login()"
				/>
				<div v-show="formError" class="form-error">
					<ul>
						<li key="error-message">
							<template>
								{{ errorMessage }}
							</template>
						</li>
					</ul>
				</div>

				<button type="button" style="float: left" class="login-form-btn" @click="login()">Login</button>
				<button
					type="button"
					style="margin-top: 10px; margin-right: 10px; display: block; float: right"
					class="login-form-btn-link"
					@click="forgotPassword = !forgotPassword"
				>
					Forgot Password?
				</button>

				<div class="login-form-images">
					<img src="@/assets/nacd_logo.jpg" />
					<img src="@/assets/cacd_logo.jpg" />
				</div>
			</form>

			<div class="login-infographic">
				<h3><b>24,000+ Chemical Products</b></h3>
				<br />
				<font size="1">
					Chemformation is a chemical information system used by chemical industry professionals such as
					distributors, formulators, chemists &amp; producers, to quickly find information on chemical
					products.
				</font>
				<br />
				<br />
				<font size="1">
					Chemformation covers industries including Food, Household &amp; Industrial Cleaners, Ink, Metal
					Finishing, Oil &amp; Gas, Paints &amp; Coatings, Personal Care, Pharma, Pulp &amp; Paper, Water
					Treatment and many more.
				</font>
				<br />
				<br />
				<br />
				<ul>
					<font size="1"><b>SEARCH PRODUCTS BY</b></font>
					<li>Product Name</li>
					<li>Trade Name</li>
					<li>Synonyms</li>
					<li>CAS Number</li>
					<li>UN Number</li>
					<li>INCI Name</li>
					<li>Product Class</li>
					<li>Product Use</li>
					<li>Manufacturer</li>
					<li>
						<font color="##00B050">Safer Chemical Ingredient List</font>
					</li>
					<li>Proposition 65 Exempt</li>
					<br />
					<font size="1"><b>PRODUCT LITERATURE</b></font>
					<li>Safety Data Sheets</li>
					<li>Technical Data Sheets</li>
					<li>Certificates</li>
					<li>
						FAQ - Product Groups &amp; End Use Products
						<font color="#FF0000"><b>NEW</b></font>
					</li>
					<br />
					<font size="1"><b>CROSS REFERENCE</b></font>
					<li>Find products that match a competitive equivalent</li>
				</ul>
			</div>

			<div class="login-infographic">
				<font size="1">
					<i>
						<p align="right">
							"I like the dynamics of the tool as we educate our Sales Team on applications &amp;
							products used, letting them have a more technical solution oriented interaction with
							their customers."
							<br />
							- Chemical Distributor
						</p>
					</i>
				</font>
				<font size="1">
					<i>
						<p align="right">
							"Chemformation is the best tool I have used in the Chemical Industry and I have been in
							the business for over 20 years."
							<br />
							- Chemical Distributor
						</p>
					</i>
				</font>
				<br />
				<br />
				<br />
				<ul>
					<font size="1"><b>SEARCH SURFACTANTS BY</b></font>
					<li>Cloud Point</li>
					<li>HLB Value</li>
					<li>Relative Solubility #</li>
					<li>Foam Characteristics</li>
					<br />
					<font size="1"><b>SEARCH SOLVENTS BY</b></font>
					<li>Aniline Point</li>
					<li>Boiling Point</li>
					<li>Evaporation Rate</li>
					<li>Flash Point</li>
					<li>KB Value</li>
					<li>Non HAP (Hazardous Air Pollutants)</li>
					<li>VOC Exempt</li>
					<br />
					<font size="1"><b>CHEMFORMATION INTERNET SEARCH</b></font>
					<li>Search Only Chemical Producer Websites with the Chemformation Custom Internet Search</li>
					<br />
					<font size="1">
						<b>
							SUPPLIER PARTNERS
							<font color="#FF0000"><b>NEW</b></font>
						</b>
					</font>
					<li>Sample Request</li>
					<li>Formulations</li>
					<li>Product Literature</li>
				</ul>
			</div>
		</template>
		<template v-else>
			<form class="form">
				<button
					type="button"
					style="margin-bottom: 10px"
					class="form-btn-link"
					@click="forgotPassword = !forgotPassword"
				>
					Go Back
				</button>

				<h2 style="margin-bottom: 2rem">
					Fill in your email address to receive instructions on resetting your password.
				</h2>

				<div v-show="formError" class="form-error">
					<ul>
						<li key="error-message">
							<template>
								{{ errorMessage }}
							</template>
						</li>
					</ul>
				</div>

				<div class="form-group">
					<label class="required" for="email">Email</label>
					<input
						id="email"
						name="email"
						type="text"
						size="56"
						maxlength="100"
						autocomplete="false"
						value=""
						class="text"
						v-model="loginDetails.username"
					/>
				</div>
				<button
					type="button"
					class="form-btn"
					style="float: right; margin-top: 0.5rem"
					@click="forgotLogin()"
				>
					Enter
				</button>
			</form>
		</template>

		<Modal v-if="success" title="Request Completed" @close="success = false">
			<template v-slot:content>
				<span>
					The instructions to reset your password have been emailed to
					{{ loginDetails.username }}.
				</span>
			</template>
		</Modal>
	</div>

	<div v-else style="overflow: hidden">
		<template>
			<div class="login">
				<div class="left-section">
					<v-card elevation="2" class="login-prompt-container">
						<template v-if="!forgotPassword">
							<v-row>
								<v-col cols="12" sm="12">
									<h2 class="login-header">LOGIN:</h2>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="10" offset-sm="-2">
									<v-text-field
										v-model="loginDetails.username"
										single-line
										outlined
										dense
										hide-details
										@keyup.enter="login()"
									>
										<template #prepend>
											<h3 class="prompt-label">Email</h3>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="10">
									<v-row>
										<v-col cols="12" sm="12">
											<v-text-field
												v-model="loginDetails.password"
												type="password"
												single-line
												outlined
												dense
												hide-details
												@keyup.enter="login()"
											>
												<template #prepend>
													<h3 class="prompt-label">Password</h3>
												</template>
											</v-text-field>
										</v-col>
										<v-col cols="12" sm="12" v-show="formError" class="form-error">
											<ul>
												<li key="error-message">
													<template>
														{{ errorMessage }}
													</template>
												</li>
											</ul>
										</v-col>
										<v-col cols="12" sm="12">
											<button
												type="button"
												class="forgot-pass"
												@click="forgotPassword = !forgotPassword"
											>
												Forgot Password?
											</button>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" sm="2">
									<v-btn class="login-btn" color="#ba0c2f" @click="login()">
										Login
										<v-icon right dark>mdi-login</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</template>
						<template v-else>
							<form class="form">
								<v-row>
									<button
										type="button"
										style="margin-bottom: 10px"
										class="form-btn-link"
										@click="forgotPassword = !forgotPassword"
									>
										Go Back
									</button>

									<template v-if="!success">
										<v-col cols="12" sm="12" class="d-flex justify-center">
											<h2 style="margin-bottom: 1rem">
												Fill in your email address to receive instructions on resetting
												your password.
											</h2>
										</v-col>

										<v-col cols="12" sm="2">
											<h1 class="prompt-label">Email</h1>
										</v-col>

										<v-col cols="12" sm="8">
											<v-text-field
												v-model="loginDetails.username"
												single-line
												outlined
												dense
												hide-details
												class="wb"
												@keyup.enter="forgotLogin()"
											></v-text-field>
											<div v-show="formError" class="form-error">
												<ul>
													<li key="error-message">
														<template>
															{{ errorMessage }}
														</template>
													</li>
												</ul>
											</div>
										</v-col>
										<v-col cols="12" sm="2">
											<v-btn class="login-btn" color="#ba0c2f" @click="forgotLogin()">
												Reset
												<v-icon right dark>mdi-lock-reset</v-icon>
											</v-btn>
										</v-col>
									</template>
									<template v-else>
										<v-col cols="12" sm="12" class="d-flex justify-center">
											<h1>
												The instructions to reset your password have been emailed to
												{{ loginDetails.username }}.
											</h1>
										</v-col>
									</template>
								</v-row>
							</form>
						</template>
					</v-card>

					<div class="description">
						<p class="prompt">
							<strong>Chemformation</strong>
							helps chemists, formulators, producers, distributors and purchasing agents search for
							and identify chemical products and ingredients.
						</p>
					</div>
				</div>
				<div class="right-section">
					<div class="section-container">
						<span class="red-font section-header">
							Up-to-date, chemical information you can trust.
						</span>
						<p class="section-description">
							Chemformation is a one-of-a-kind, online resource for fast and accurate data on
							chemical products and ingredients covering a variety of industries. The database brings
							together information all in one place, making chemical products and related information
							easily searchable.
							<router-link to="/request" class="request-access-link">
								Request access today
							</router-link>
							and we will arrange for a detailed demo.
						</p>
					</div>
				</div>
			</div>

			<v-row>
				<v-col cols="12" sm="12">
					<v-row>
						<v-col cols="12" sm="12">
							<span class="feature-header">
								Chemformation offers dynamic search criteria designed for a range of professionals
								and houses tens of thousands of chemical products, with new products added daily.
							</span>
						</v-col>
					</v-row>
					<v-container>
						<v-row>
							<v-col cols="12" sm="3" class="d-flex justify-center">
								<v-card
									elevation="2"
									:max-width="this.isMobile ? 'auto' : '40rem'"
									class="kp-container"
								>
									<span class="kp-description">
										Easily identify chemical products and alternative sources.
									</span>
								</v-card>
							</v-col>
							<v-col cols="12" sm="3" class="d-flex justify-center">
								<v-card
									elevation="2"
									:max-width="this.isMobile ? 'auto' : '40rem'"
									class="kp-container"
								>
									<span class="kp-description">Save time and answer customer questions.</span>
								</v-card>
							</v-col>
							<v-col cols="12" sm="3" class="d-flex justify-center">
								<v-card
									elevation="2"
									:max-width="this.isMobile ? 'auto' : '40rem'"
									class="kp-container"
								>
									<span class="kp-description">
										Enjoy a user-friendly interface with extensive ways to search for products.
									</span>
								</v-card>
							</v-col>
							<v-col cols="12" sm="3" class="d-flex justify-center">
								<v-card
									elevation="2"
									:max-width="this.isMobile ? 'auto' : '40rem'"
									class="kp-container"
								>
									<span class="kp-description">
										Empower all team members, from chemists to sales people.
									</span>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
					<v-row>
						<v-col cols="12" sm="12" class="watch-demo-btn">
							<router-link to="/tutorial">
								<v-btn large class="white-font watch-demo-btn-text" color="#ba0c2f">
									Watch the Demo
								</v-btn>
							</router-link>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<div class="carousel-parent">
				<div class="carousel-container">
					<v-row>
						<v-col cols="12" sm="12">
							<span class="feature-header">
								Chemformation supports thousands of users inside the world's leading chemical
								companies.
							</span>
						</v-col>
					</v-row>
					<hr class="carousel-delimeter" />
					<v-row>
						<v-col cols="12" sm="12" class="d-flex justify-center">
							<v-carousel
								cycle
								interval="10000"
								hide-delimiter-background
								:height="this.isMobile ? 1100 : 300"
								class="carousel"
							>
								<v-carousel-item key="c-1">
									<div class="carousel-quote-container text-h3 red-font px-8">
										<span class="carousel-quote">
											"Chemformation was a fabulous discovery for our company. Several of our
											team members around the world are now using it as it complements and
											facilitates their search."
										</span>
										<span class="carousel-quote mt-2">
											Jean-Luc Carriere, Vice President, North America, CellMark Basic
											Materials
										</span>
									</div>
								</v-carousel-item>
								<v-carousel-item key="c-2">
									<div class="carousel-quote-container text-h3 red-font px-8">
										<span class="carousel-quote">
											"I use Chemformation everyday I'm working. It's a tool that's essential
											for my day-to-day success."
										</span>
										<span class="carousel-quote mt-2">
											Jesse Grosskreutz, Corporate Account Executive, Brenntag Great Lakes
										</span>
									</div>
								</v-carousel-item>
								<v-carousel-item key="c-3">
									<div class="carousel-quote-container text-h3 red-font px-8">
										<span class="carousel-quote">
											"I love Chemformation and use it weekly and I find it user friendly and
											extremely helpful."
										</span>
										<span class="carousel-quote mt-2">
											Packer Cox, Marketing Manager, Shrieve Chemical
										</span>
									</div>
								</v-carousel-item>
								<v-carousel-item key="c-4">
									<div class="carousel-quote-container text-h3 red-font px-8">
										<span class="carousel-quote">
											"I have to say, Chemformation makes me better out in the field almost
											every day. I am grateful I am every time I find a new gem through
											Chemformation."
										</span>
										<span class="carousel-quote mt-2">
											Chuck Hulsey, Technical Sales, Norman, Fox &amp; Company
										</span>
									</div>
								</v-carousel-item>
								<v-carousel-item key="c-5">
									<div class="carousel-quote-container text-h3 red-font px-8">
										<span class="carousel-quote">
											"Chemformation is easy to use with multiple ways to look up chemical
											and supplier info. They have more data than any other chemical sourcing
											site I have tried, and they are always looking to expand to their
											knowledge base to provide the best and most up-to-date information."
										</span>
										<span class="carousel-quote mt-2">
											Katie West, Purchasing Manager, Nease Performance Chemicals
										</span>
									</div>
								</v-carousel-item>
								<v-carousel-item key="c-6">
									<div class="carousel-quote-container text-h3 red-font px-8">
										<span class="carousel-quote">
											"Chemformation is an excellent resource that I use often in formulation
											work. It is easy to use and full of valuable information, particularly
											about surfactants."
										</span>
										<span class="carousel-quote mt-2">
											Shoaib Arif, Research Fellow, Pilot Chemical Co.
										</span>
									</div>
								</v-carousel-item>
								<v-carousel-item key="c-7">
									<div class="carousel-quote-container text-h3 red-font px-8">
										<span class="carousel-quote">"Robust Database, Organized Research"</span>
										<span class="carousel-quote mt-2">
											Akshay Changedia, Supply Chain Analysts - Ecolab
										</span>
									</div>
								</v-carousel-item>
								<v-carousel-item key="c-8">
									<div class="carousel-quote-container text-h3 red-font px-8">
										<span class="carousel-quote">
											"Chemformation is a one stop shop for all your chemical needs. The
											amount of information they provide is second to none. The multiple ways
											in which you can search for what you need is the best, including the
											internet search capabilities which eliminates all the unnecessary
											noise. Chemformation is pinned on my browser as it is my go-to chemical
											information source! Such a game changer!"
										</span>
										<span class="carousel-quote mt-2">
											Kim Surber, Quality Manager for Dow Oxygenated Solvents, Dow Chemical
										</span>
									</div>
								</v-carousel-item>
							</v-carousel>
						</v-col>
					</v-row>
				</div>
			</div>

			<v-row class="d-flex align-center about-parent">
				<v-col cols="12" sm="8">
					<div class="about-container">
						<span class="text-h3 red-font mb-4">About our company.</span>
						<p class="section-description mt-4">
							Chemformation was founded by Joe Broome, an industry veteran with decades of experience
							in chemical distribution. After helping thousands of customers answer a range of
							chemical ingredient questions, Joe realized a need for a tool like Chemformation.
							Started in 2008, Chemformation remains the best online resource to deliver
							comprehensive and up-to-date chemical information. Family-owned and -operated,
							Chemformation is led by Joe and his children, Paul Broome and Holly Myers, all of whom
							work to provide the highest level of service to the chemical industry.
						</p>
					</div>
				</v-col>
				<v-col cols="12" sm="4" class="d-flex justify-center">
					<router-link to="/contact" style="text-decoration: none">
						<v-card class="contact-btn" color="#ba0c2f">
							<span class="white-font">
								Learn more by contacting
								<b>Chemformation</b>
								today.
							</span>
						</v-card>
					</router-link>
				</v-col>
			</v-row>

			<template>
				<div class="relative-container">
					<v-img
						:src="require('@/assets/svg/Chemformation_Icon.svg')"
						aspect-ratio="1"
						contain
						class="image"
					/>
					<v-container fluid class="sub-footer py-12">
						<v-row>
							<v-col cols="12" sm="12">
								<p class="topsubheader">
									Chemformation makes searching for chemical ingredients easy and fast.
								</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="12">
								<span class="subheader">
									Request access today and we'll arrange for a detailed demo focused on your
									unique needs.
								</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="12" class="watch-demo-btn">
								<router-link to="/tutorial">
									<v-btn large class="red-font watch-demo-btn-text" color="#fff">
										Watch the Demo
									</v-btn>
								</router-link>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</template>

			<v-container fluid class="footer-container">
				<v-row class="footer-row">
					<v-col cols="12" sm="4" class="d-flex justify-center">
						<img class="footer-logo" :src="require('@/assets/svg/Chemformation_logo_Primary_W.svg')" />
					</v-col>
					<v-col cols="12" sm="8">
						<v-row>
							<v-col cols="12" sm="4">
								<v-row class="mb-1">
									<span>CONTACT:</span>
								</v-row>
								<v-row>
									<span>214-676-4885</span>
								</v-row>
								<v-row class="mb-1">
									<span><a href="mailto:info@chemformation.com">info@chemformation.com</a></span>
								</v-row>
							</v-col>
							<v-col cols="12" sm="3">
								<v-row class="mb-1">
									<span>CONNECT:</span>
								</v-row>
								<v-row class="mb-1">
									<a href="https://www.linkedin.com/company/chemformation" target="_blank">
										<img width="35px" :src="require('@/assets/svg/linkedin.svg')" />
									</a>
								</v-row>
							</v-col>
							<v-col cols="12" sm="5">
								<v-row class="mb-1"><span>AFFILIATIONS:</span></v-row>
								<v-row class="mb-1" style="display: flex; justify-content: space-evenly">
									<img
										class="mr-3"
										width="125px"
										:src="require('@/assets/ACD_LOGO_White.svg')"
									/>
									<img
										class="mr-3"
										width="135px"
										:src="require('@/assets/svg/nacd_2024_sponsor_logo.svg')"
									/>
									<img width="180px" :src="require('@/assets/FECC-Logo_2023.svg')" />
								</v-row>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="12">
								<v-row>
									<span>All contents © Chemformation. 2023. All rights reserved.</span>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
	name: "Login",
	components: {
		Modal,
	},
	data() {
		return {
			loginDetails: {
				username: "",
				password: "",
			},
			forgotPassword: false,
			formError: false,
			errorMessage: "",
			success: false,
			isMobile: false,
		};
	},
	methods: {
		async login() {
			this.formError = false;
			this.$store
				.dispatch("login", this.loginDetails)
				.then(() => {
					this.$router.push("/product-search");
				})
				.catch((error) => {
					if (error.response.status == 401) {
						this.formError = true;

						if (error.response.data.message) {
							this.errorMessage = error.response.data.message;
						}
					} else {
						console.log(error);
					}
				});
		},

		async forgotLogin() {
			this.formError = false;
			try {
				let resp = await this.$http.post(`/api/account/request-password-reset`, {
					username: this.loginDetails.username,
				});

				if (resp) {
					this.success = true;
				}
			} catch (error) {
				this.formError = true;
				this.errorMessage = error.response.data;
			}
		},
	},
	mounted() {
		this.isMobile = this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
	},
};
</script>

<style lang="scss" scoped>
.login {
	position: relative;
	display: flex;
	flex-flow: row wrap;

	&-form {
		display: inline-block;
		margin: 10px;
		width: 20rem;

		label {
			display: block;
			clear: both;
			margin: 10px;
			font-size: 12px;
		}

		input {
			display: block;
			clear: both;
			margin: 10px;
			border: 1px solid #a6c9e2;
			border-radius: 5px;
			background: #fcfdfd url("~@/assets/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
			color: #222;
			width: calc(100% - 20px);
		}

		&-btn {
			display: inline-block;
			margin: 10px;
			padding: 4px 11px;
			border: 1px solid #c5dbec;
			border-radius: 5px;
			background: #dfeffc url("~@/assets/ui-bg_glass_85_dfeffc_1x400.png") 50% 50% repeat-x;
			color: #2e6e9e;
			font-size: 1.1rem;

			&-link {
				color: #2e6e9e;
				font-size: 1.1rem;
				text-decoration: underline;
			}
		}

		&-images {
			margin-top: 210px;

			img {
				display: block;
				clear: both;
				margin: 20px;
			}
		}
	}

	&-infographic {
		background-color: #c8dbdf;
		padding: 10px;
		border: 1px solid #c0c0c0;
		border-radius: 5px;
		height: 514px;
		width: 272px;
		margin: 5px;
		line-height: initial;

		h3 {
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.08em;
			margin: 0;
		}

		font {
			font-size: 1rem;
		}

		ul {
			li {
				font-size: 0.9rem;
			}
		}
	}
}

.modal-overlay {
	height: auto;
}

.form-error {
	color: red;
	font-size: 16px;
}
</style>

<style lang="scss" scoped>
.login {
	display: flex;
	flex-direction: row;
	font-family: "Roboto", sans-serif !important;
}

.left-section,
.right-section {
	flex: 1;
}

.login-prompt {
	margin-bottom: 10px;
}

.description {
	margin-top: 20px;
}

.left-section {
	background-color: #ba0c2f;
	background-image: url("/assets/svg/network.svg");
}

.right-section {
	background-color: #f1f1f1;
}

.login-prompt-container {
	background-color: #f1f1f1 !important;
	margin: 20px 40px;
	padding: 4rem;
}

.login-header {
	color: #ba0c2f;
}

.prompt {
	text-align: center;
	color: #fff;
	font-size: 3rem;
	margin: 20px 40px;
}

.prompt-label {
	color: #878787;
	text-align: end;
	min-width: 85px;
}

.forgot-pass {
	color: #878787;
	font-size: 1.5rem;
}

.forgot-pass:hover {
	text-decoration: underline;
	color: #ba0c2f;
}

.login-btn {
	color: #fff !important;
	width: inherit !important;
}

.wb {
	background-color: #fff !important;
}

.red-font {
	color: #ba0c2f;
}

.white-font {
	color: #fff;
}

.request-access-link {
	color: #ba0c2f !important;
	font-weight: bold;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.section-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 0 5rem;

	background: url("~@/assets/svg/Chemformation_C_only_white.svg") no-repeat;
	background-size: auto 80%;
	background-position: 90% center;
}

.section-header {
	font-size: 5rem;
	margin-bottom: 1rem;
}

.section-description {
	font-size: 2rem;
}

.feature-header {
	font-size: 3rem;
	display: flex;
	justify-content: center;
	text-align: center;
	padding: 40px 13vw;
}

@media (max-width: 768px) {
	.kp-container {
		height: auto;
		padding: 25px;
	}
}

.kp-container {
	padding: 50px;
	background-color: #f1f1f1;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
}

.kp-container::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	border-left: 1.5rem solid transparent;
	border-right: 1.5rem solid transparent;
	border-top: 1.5rem solid #ba0c2f;
}

.kp-description {
	font-size: 2rem;
	text-align: center;
}

.watch-demo-btn {
	display: flex;
	justify-content: center;
	margin-top: 25px;
}

.watch-demo-btn-text {
	font-size: 1.25rem;
}

.carousel-parent {
	background-color: #f1f1f1;
}

.carousel-container {
	margin-top: 25px;

	background: url("~@/assets/svg/Chemformation_C_only_white.svg") no-repeat;
	background-size: auto 90%;
	background-position: 90% center;
}

.carousel-delimeter {
	width: 15%;
	margin: auto;
}

.carousel-quote {
	display: block;
	text-align: center;
	font-style: italic;
	line-height: 1.5;
}

.carousel-quote-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.about-parent {
	margin-bottom: 75px;
}

.about-container {
	padding: 6rem;
}

.contact-btn {
	font-size: 1.5rem;
	padding: 2rem;
	width: 300px;
	height: 100px;
	background-color: #ba0c2f;
	color: #fff;
	display: flex;
	align-items: center;
	border-radius: 15px;
	cursor: pointer;

	span {
		text-align: center;
	}
}

.sub-footer {
	color: #fff;
	background-color: #ba0c2f;
	.topsubheader {
		font-size: 3rem;
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.subheader {
		font-size: 1.5rem;
		display: flex;
		justify-content: center;
		text-align: center;
	}
}

.relative-container {
	width: 100%;
	position: relative;
}

.image {
	border: 4px solid white;
	border-radius: 50px;
	position: absolute;
	left: 50%;
	top: -60px;
	transform: translateX(-50%);
	max-width: 80px;
	max-height: 80px;
}

.footer-row {
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 16px;

	a:link,
	a:visited {
		color: #fff !important;
	}
}

.footer-container {
	background-color: #1a1a1a;
	color: #fff;
	padding: 50px;
}

.footer-logo {
	max-height: 12rem;
	margin-bottom: 25px;
}
</style>
